<template>
  <div class="order-center-container">
    <div class="container">
      <el-table></el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 880px;
  margin: 0 auto;
  padding: 50px 120px 50px;
  background: #fff;
  min-height: 800px;
}
</style>
